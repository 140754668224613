<div class="container">
  <div class="modal">
    <dynamic-logo />
    <div class="modal-content">
      <router-outlet></router-outlet>

      <div>
        <ng-container *ngIf="isSuccess; else failed">
          <div>
            <p>
              <b>Attention!</b>
              Once you initiate this process, your current token will be revoked, and our bot will no longer communicate
              with Yelp on your behalf until you complete the new token setup.
            </p>

            <button
              mat-button
              mat-raised-button
              color="warn"
              (click)="openConfirmDialog()"
              class="confirm-button"
              [disabled]="isLoading">
              <span *ngIf="!isLoading">Continue</span>
              <mat-progress-spinner color="primary" mode="indeterminate" *ngIf="isLoading" diameter="25">
              </mat-progress-spinner>
            </button>
          </div>
        </ng-container>

        <ng-template #failed>
          <span style="display: flex; align-items: center">
            Something went wrong, please try again or contact us if you need help
            <a href="http://wa.link/g2nnnq" target="_blank">
              <img src="assets/WhatsApp.svg" alt="contact us on whatsapp" width="100px" />
            </a>
          </span>

          <button mat-button mat-raised-button color="primary" routerLink="/app/sources" style="width: 100%">
            Continue to {{ logoService.hostText }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
