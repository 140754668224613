import {AfterViewInit, Component, Input} from "@angular/core";
import {
  YelpConfigWelcomeMessage,
  YelpConfigWelcomePredefinedMessages
} from "../../../../../../server/src/db/classes-helpers/yelpConfig";
import {OperationConfigComponent} from "../operation-config.component";
import {OperationConfigBaseComponent} from "../operation-config-base.component";
import {cloneDeep} from "lodash";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: "app-operation-config-welcome-message",
  templateUrl: "./operation-config-welcome-message.component.html",
  styleUrl: "./operation-config-welcome-message.component.scss"
})
export class OperationConfigWelcomeMessageComponent
  extends OperationConfigBaseComponent<YelpConfigWelcomeMessage>
  implements AfterViewInit
{
  @Input() override inputConfig: YelpConfigWelcomeMessage | null;
  @Input() override inputGlobalConfig: YelpConfigWelcomeMessage;

  public tempGlobalConfig: YelpConfigWelcomeMessage;
  public isGlobalConfigWasApplied: boolean;

  constructor(public override operationConfigComponent: OperationConfigComponent) {
    super(operationConfigComponent);
  }

  ngAfterViewInit() {
    this.tempGlobalConfig = cloneDeep(this.globalConfig);
    this.isGlobalConfigWasApplied = cloneDeep(this.isGlobalConfigApplied);
  }

  protected override notifyConfigChange(): void {
    this.operationConfigComponent.welcomeMessageConfigChaged(this.config, this.isGlobalConfigApplied);
  }

  public removeKeyword(arr: string[], keyword: string) {
    const index = arr.indexOf(keyword);
    if (index >= 0) {
      arr.splice(index, 1);
    }
  }

  public removeMessage(message: YelpConfigWelcomePredefinedMessages) {
    const idx = this.config.predefinedMessages.indexOf(message);
    this.config.predefinedMessages.splice(idx, 1);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.config.predefinedMessages, event.previousIndex, event.currentIndex);
  }

  protected addElement(arr: string[], event): void {
    const input = event.input || event.target;
    const value = (input?.value || "").trim();

    if (value && !arr.includes(value)) {
      arr.push(value);
    }

    input.value = "";
  }

  public addMessage(): void {
    const message: YelpConfigWelcomePredefinedMessages = {
      text: "",
      words: [],
      preventFollowUps: false
    };

    this.config.predefinedMessages.splice(-1, 0, message);
  }

  public enableGlobalSettings(): void {
    this.toggleGlobalSettings();
    this.restoreAiToGlobalConfig();
  }

  public restoreAiToGlobalConfig(): void {
    if (this.isGlobalConfigApplied) {
      this.config.isAiEnabled = this.tempGlobalConfig?.isAiEnabled;
      this.config.aiConfig = this.tempGlobalConfig?.aiConfig;
    }
  }

  public toggleEnableAi(): void {
    if (!this.config.aiConfig) {
      this.config.aiConfig = {
        canDo: "",
        cantDo: "",
        businessDescription: ""
      };
    }

    // Restore to global config if it was applied
    if (this.isGlobalConfigApplied && this.isGlobalConfigWasApplied) {
      this.config.aiConfig = this.tempGlobalConfig.aiConfig;
    }
  }
}
