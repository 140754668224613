<div class="container" [class.pointerEventsNone]="isAuthInProgress">
  <div class="modal">
    <a href="/">
      <dynamic-logo />
    </a>
    <div class="modal-content" [class.disabled]="isAuthInProgress">
      <router-outlet></router-outlet>
      <app-text-divider>Or</app-text-divider>

      <div class="social-button">
        <asl-google-signin-button
          type="standard"
          [class.disabled]="isAuthInProgress"
          text="signup_with"
          size="large"
          [width]="330"
          shape="rectangular"
          theme="outline"></asl-google-signin-button>
      </div>

      <div class="error-field">{{ loginError }}</div>
    </div>
    <div class="progress-indicator" *ngIf="isAuthInProgress">
      <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
    </div>
  </div>
</div>
