<h2 mat-dialog-title>Contact Us</h2>

<div mat-dialog-content style="white-space: pre-wrap">
  <table>
    <tr>
      <td>Phone:</td>
      <td>
        <div *ngIf="!isMobile">{{ logoService.phone }}</div>
        <a *ngIf="isMobile" [tabIndex]="-1" href="tel:{{ logoService.phone }}">{{ logoService.phone }}</a>
      </td>
    </tr>
    <tr>
      <td>Email:</td>
      <td>
        <a [tabIndex]="-1" href="mailto:{{ logoService.email }}">{{ logoService.email }}</a>
      </td>
    </tr>
    <tr>
      <td>Whatsapp:</td>
      <td><a [tabIndex]="-1" href="https://wa.link/f0z3in">Whatsapp link</a></td>
    </tr>
  </table>
  <br />
  <div>857 N ORANGE DR LOS ANGELES, CA 90038</div>
  <!--  <br/>-->
  <!--  <br/>-->
  <!--  <h4>Leave your info:-->
  <!--  <form>-->
  <!--    <mat-form-field floatLabel="always">-->
  <!--      <mat-label>Name</mat-label>-->
  <!--      <input matInput autocomplete="given-name" [(ngModel)]="form.name">-->
  <!--    </mat-form-field>-->

  <!--    <mat-form-field floatLabel="always">-->
  <!--      <mat-label>Telephone</mat-label>-->
  <!--      <input matInput autocomplete="tel" [(ngModel)]="form.phone">-->
  <!--    </mat-form-field>-->

  <!--    <mat-form-field floatLabel="always">-->
  <!--      <mat-label>email</mat-label>-->
  <!--      <input matInput autocomplete="email" [(ngModel)]="form.email">-->
  <!--    </mat-form-field>-->
  <!--    <button mat-button mat-raised-button (click)="dialogRef.close();">-->
  <!--      Submit-->
  <!--    </button>-->
  <!--  </form></h4>-->

  <div style="font-size: 0.8rem">{{ data?.comment }}</div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">Close</button>
</div>
