import {Injectable} from "@angular/core";
import {ConnectionService} from "./connection.service";
import {ConfirmDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog.component";
import {NzDialogService} from "../utils/services/nz-dialog.service";
import {UsageReport} from "../../../../server/src/db/classes/billing";
import {Source} from "../../../../server/src/db/classes/source";
import {trialDays} from "../../../../server/src/modules/billing/statics";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class BillingService {
  constructor(
    private connectionService: ConnectionService,
    private nzDialogService: NzDialogService
  ) {}

  createBillingSession = async (sourceId, pathname?) => {
    return await this.connectionService.post("billing/create", {
      sourceId,
      pathname
    });
  };

  async open(sourceId?) {
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {maxWidth: 0});
    try {
      const res = await this.createBillingSession(sourceId, location.pathname);
      window.location.href = res.url;
    } catch (e) {
      dialogRef.close();
    }
  }

  async getUsageReports(): Promise<UsageReport[]> {
    return await this.connectionService.post("billing/usageReports", {});
  }

  public async promptForTrial(source: Source) {
    const dialogRef = this.nzDialogService.open(ConfirmDialogComponent, {
      maxWidth: 600,
      data: {
        title: `Start Your ${trialDays}-Day Free Trial`,
        mainText: `Enjoy full access with no commitments for ${trialDays} days.`,
        action: "Activate My Free Trial"
      }
    });
    const confirm = await dialogRef.afterClosed().pipe(untilDestroyed(this)).toPromise();
    if (confirm) {
      await this.open(source._id);
    }
  }
}
