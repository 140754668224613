import { CanActivateFn, Router } from "@angular/router";
import { inject, NgZone } from "@angular/core";
import { SessionService } from "../Services/session.service";
import { StorageService } from "../Services/storage.service";
import { ClientSession } from "../../../../server/src/db/classes-helpers/clientSession";

export const MainGuard: CanActivateFn = async (route, state) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return undefined;
  }

  if (getCookie("login")) {
    const loginInfo = getCookie("login");
    if (loginInfo) {
      const loginSession = JSON.parse(loginInfo) as ClientSession;
      if (loginSession.token) {
        StorageService.setItem("session", loginSession);
        StorageService.setItem("sessionId", loginSession.token);
      }
    }
  }
  if (sessionService.session) {
    return true;
  }
  router.navigateByUrl("/login");
  return false;
};
